<template>
  <div>
    <b-modal
      id="bet-invoice-detail-modal"
      size="xl"
      :title="$t('general.betHistory')"
      hide-footer
      centered
    >
      <div
        v-if="invoice.invoiceNo"
        :class="lotteryId == 2 ? `lottery-kh` : `lottery-vn`"
      >
        <b-row>
          <b-col md="6">
            {{ $t("field.lotteryName") }}:
            {{
              $i18n.locale == "en" ? invoice.lotteryName : invoice.lotteryNameKh
            }}
          </b-col>
          <b-col md="6">
            {{ $t("field.lotteryTime") }}:
            {{
              moment(invoice.lotteryTimeName, "HH:mm")
                .locale($i18n.locale)
                .format("h:mm a")
            }}
          </b-col>
          <b-col md="6">
            {{ $t("field.invoiceNumber") }}: {{ invoice.invoiceNo }}
          </b-col>
          <b-col md="6">
            {{ $t("field.amount") }}:
            {{ invoice.amount | currency(invoice.ccy) }}
          </b-col>
          <b-col md="6">
            {{ $t("field.commission") }}:
            <span class="text-danger">
              {{ invoice.commission | currency(invoice.ccy) }}
            </span>
          </b-col>
          <b-col md="6">
            {{ $t("field.total") }}:
            <span class="text-positive">
              {{ invoice.total | currency(invoice.ccy) }}
            </span>
          </b-col>
          <b-col md="6">
            {{ $t("field.winLoss") }}:
            <span
              :class="{
                'text-positive': invoice.winLose >= 0,
                'text-danger': invoice.winLose < 0,
              }"
            >
              {{ invoice.winLose | currency(invoice.ccy) }}
            </span>
          </b-col>
          <b-col md="6">
            {{ $t("field.payback") }}:
            <span
              :class="{
                'text-positive': invoice.payoutAmount >= 0,
                'text-danger': invoice.payoutAmount < 0,
              }"
            >
              {{ invoice.payoutAmount | currency(invoice.ccy) }}
            </span>
          </b-col>
          <b-col md="6">
            {{ $t("field.date") }}: {{ invoice.betDate | formatDate }}
          </b-col>
        </b-row>
        <n-table :fields="fields" :items="invoice.detail">
          <template #cell(number)="data">
            <b-link class="link-underline" @click="viewNumberDetail(data.item)">
              {{ data.item.number }}
            </b-link>
          </template>
          <template #cell(amount)="data">
            {{ data.item.amount | currency(data.item.ccy) }}
          </template>
          <template #cell(total)="data">
            {{ data.item.total | currency(data.item.ccy) }}
          </template>
          <template #cell(commission)="data">
            {{ data.item.commission | currency(data.item.ccy) }}
          </template>
          <template #cell(payoutAmount)="data">
            <b-link
              class="link-underline"
              :class="{
                'text-positive': data.item.payoutAmount >= 0,
                'text-danger': data.item.payoutAmount < 0,
              }"
              @click="viewWinLossNumberDetail(data.item)"
            >
              {{ data.item.payoutAmount | currency(data.item.ccy) }}
            </b-link>
          </template>
        </n-table>
      </div>
    </b-modal>

    <b-modal
      id="winlist-detail-modal"
      size="xl"
      :title="$t('field.result')"
      hide-footer
      centered
    >
      <b-row>
        <b-col cols="auto" v-for="(result, i) in results" :key="`result-${i}`">
          <h4 class="mb-0 result-text">
            {{ result }}
          </h4>
        </b-col>
      </b-row>

      <h6 class="mt-2">
        {{ $t("field.winList") }}
      </h6>
      <n-table
        class="bet-table"
        sticky-header
        :fields="winListTableColumn"
        :items="winList"
        show-empty
        :empty-text="$t('general.noRecord')"
      >
        <template #cell(number)="data">
          {{ data.item.number }}
        </template>
        <template #cell(amount)="data">
          {{ data.item.amount | currency(data.item.ccy) }}
        </template>
        <template #cell(total)="data">
          {{ data.item.total | currency(data.item.ccy) }}
        </template>
        <template #cell(commission)="data">
          {{ data.item.commission | currency(data.item.ccy) }}
        </template>
        <template #cell(payoutAmount)="data">
          <span
            :class="{
              'text-positive': data.item.payoutAmount > 0,
              'text-danger': data.item.payoutAmount < 0,
            }"
          >
            {{ data.item.payoutAmount | currency(data.item.ccy) }}
          </span>
        </template>
      </n-table>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BCol, BLink } from "bootstrap-vue";
import NTable from "@/components/NTable.vue";
import fields from "./detailTableColumn";
import moment from "moment";
import winListTableColumn from "./winListTableColumn";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BLink,
    NTable,
  },
  data() {
    return {
      lotteryId: null,
      invoice: {},
      isShow: false,

      winList: [],
      results: [],

      fields,
      winListTableColumn,
      moment,
    };
  },
  methods: {
    viewNumberDetail(item) {
      this.$bvModal
        .msgBoxOk(item.numberDetail.join(", "), {
          modalClass: "number-detail",
          size: "sm",
          buttonSize: "sm",
          okVariant: "info",
          okTitle: this.$t("button.close"),
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("confirm");
          }
        });
    },
    viewWinLossNumberDetail(item) {
      this.results = [...item.result];
      this.winList = [...item.winList];

      this.$bvModal.show("winlist-detail-modal");
    },
    show(invoice, lotteryId) {
      this.lotteryId = lotteryId;
      this.invoice = { ...invoice };
      this.$bvModal.show("bet-invoice-detail-modal");
    },
    hide() {
      this.invoice = {};
      this.$bvModal.hide("bet-invoice-detail-modal");
    },
  },
};
</script>