var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"invoice-detail-modal","size":"xl","title":_vm.$t('general.betHistory'),"hide-footer":"","centered":""}},[_c('div',{class:_vm.params.lotteryId == 2 ? "lottery-kh" : "lottery-vn"},[_c('n-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"busy":_vm.loading,"current-page":_vm.params.page,"per-page":_vm.$store.state.pagination.perPage,"total":_vm.total},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [_c('b-tr',{staticClass:"text-center"},[_c('b-th',{attrs:{"colspan":10}},[_vm._v(" "+_vm._s(_vm.invoice.userName)+" ")])],1)]},proxy:true},{key:"cell(lotteryName)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? data.item.lotteryName : data.item.lotteryNameKh)+" ")]}},{key:"cell(lotteryTimeName)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.moment(data.item.lotteryTimeName, "HH:mm") .locale(_vm.$i18n.locale) .format("h:mm a"))+" ")]}},{key:"cell(turnover)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(data.item.turnover,data.item.ccy))+" ")])]}},{key:"cell(commission)",fn:function(data){return [_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm._f("currency")(data.item.commission,data.item.ccy))+" ")])]}},{key:"cell(netWl)",fn:function(data){return [_c('span',{class:{
              'text-positive': data.item.netWl >= 0,
              'text-danger': data.item.netWl < 0,
            }},[_vm._v(" "+_vm._s(_vm._f("currency")(data.item.netWl,data.item.ccy))+" ")])]}},{key:"cell(newWl)",fn:function(data){return [_c('span',{class:{
              'text-positive': data.item.newWl >= 0,
              'text-danger': data.item.newWl < 0,
            }},[_vm._v(" "+_vm._s(_vm._f("currency")(data.item.newWl,data.item.ccy))+" ")])]}},{key:"cell(betDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.betDate,"DD-MM-YYYY"))+" ")]}},{key:"custom-foot",fn:function(){return [(!_vm.loading)?_c('b-tr',[_c('b-th',{attrs:{"colspan":"5"}}),_c('b-th',{staticClass:"text-right"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalReport.turnover,_vm.totalReport.ccy))+" ")])]),_c('b-th',{staticClass:"text-right"},[_c('h5',{staticClass:"font-weight-bold",class:{
                  'text-positive': _vm.totalReport.netWl >= 0,
                  'text-danger': _vm.totalReport.netWl < 0,
                }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalReport.netWl,_vm.totalReport.ccy))+" ")])]),_c('b-th',{staticClass:"text-right"},[_c('h5',{staticClass:"font-weight-bold text-danger"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalReport.commission,_vm.totalReport.ccy))+" ")])]),_c('b-th',{staticClass:"text-right"},[_c('h5',{staticClass:"font-weight-bold",class:{
                  'text-positive': _vm.totalReport.newWl >= 0,
                  'text-danger': _vm.totalReport.newWl < 0,
                }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalReport.newWl,_vm.totalReport.ccy))+" ")])]),_c('b-th')],1):_vm._e()]},proxy:true}])}),_c('n-pagination',{ref:"pagination",staticClass:"mt-2",attrs:{"total":_vm.total,"per-page":_vm.$store.state.pagination.perPage,"page":_vm.params.page},on:{"change":_vm.list}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }