export default [
  {
    key: "increasement",
    label: "#",
  },
  {
    key: "lotteryName",
    label: "field.lotteryName",
  },
  {
    key: "lotteryTimeName",
    label: "field.lotteryTime",
  },
  {
    key: 'userName',
    label: 'field.username',
  },
  {
    key: 'turnover',
    label: 'field.turnover',
    tdClass: 'text-right',
  },
  {
    key: 'netWl',
    label: 'field.netWinLoss',
    tdClass: 'text-right',
  },
  {
    key: 'commission',
    label: 'field.commission',
    tdClass: 'text-right',
  },
  {
    key: 'newWl',
    label: 'field.newWinLoss',
    tdClass: 'text-right',
  },
  {
    key: "betDate",
    label: "field.date",
  },
  {
    key: "actions",
    label: "field.actions",
  },
];