export default [
  {
    key: "increasement",
    label: "#",
  },
  {
    key: "invoiceNo",
    label: "field.invoiceNumber",
  },
  {
    key: "lotteryName",
    label: "field.lotteryName",
  },
  {
    key: "lotteryTimeName",
    label: "field.lotteryTime",
  },
  {
    key: "amount",
    label: "field.amount",
  },
  {
    key: "commission",
    label: "field.commission",
  },
  {
    key: "total",
    label: "field.total",
  },
  {
    key: "winLose",
    label: "field.winLoss",
  },
  {
    key: "payoutAmount",
    label: "field.payback",
  },
  {
    key: "betDate",
    label: "field.date",
  },
  {
    key: "actions",
    label: "field.actions",
  },
];