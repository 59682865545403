<template>
  <div>
    <b-modal
      id="modal-bet-history"
      hide-footer
      centered
      :title="$t('general.betHistory')"
      size="xl"
      @hide="isShow = false"
    >
      <div :class="lotteryClass">
        <n-search-container
          :active="true"
          :show-header="false"
          @search="search"
          @reset="reset"
          v-if="searchFields.length"
          :loading="loading"
        >
          <n-search-input ref="search" :fields="searchFields" v-model="params">
            <template #period="item">
              <b-form-group
                :label-for="item.field.key"
                :label="$t(item.field.label)"
              >
                <b-form-radio-group
                  :ref="item.field.key"
                  v-if="item.field.type === 'radio'"
                  :id="item.field.key"
                  v-model="params[item.field.key]"
                  buttons
                  button-variant="outline-primary rounded-0"
                  class="flex-wrap"
                >
                  <b-form-radio
                    :value="option.value"
                    v-for="option in item.field.options"
                    :key="option.value"
                  >
                    {{ $t(option.text) }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </template>
            <template #lotteryId="item">
              <b-form-group :label-for="item.field.key">
                <label>
                  {{ $t(item.field.label) }}
                </label>
                <n-single-select
                  :reduce="true"
                  v-model="params.lotteryId"
                  :selection-label="$i18n.locale == 'en' ? 'name' : 'nameKh'"
                  :options="lotteries"
                  :clearable="false"
                  :readonly="true"
                ></n-single-select>
              </b-form-group>
            </template>
            <template #lotteryTimeId="item">
              <b-form-group :label-for="item.field.key">
                <label>
                  {{ $t(item.field.label) }}
                </label>
                <n-single-select
                  :key="`lottery-time-${params.lotteryId}`"
                  :reduce="true"
                  v-model="params.lotteryTimeId"
                  :options="lotteryTimes"
                  :clearable="false"
                ></n-single-select>
              </b-form-group>
            </template>
            <template #post="item">
              <b-form-group :label-for="item.field.key">
                <label>
                  {{ $t(item.field.label) }}
                </label>
                <n-single-select
                  :reduce="true"
                  selection-key="post"
                  selection-label="post"
                  v-model="params.post"
                  :options="channels"
                  :clearable="true"
                ></n-single-select>
              </b-form-group>
            </template>
          </n-search-input>
        </n-search-container>
        <div>
          <b-row>
            <b-col>
              <label>{{ $t("general.entries") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>
          </b-row>
          <n-table
            @sort-changed="sortChanged"
            :fields="fields"
            :items="items"
            :busy="loading"
            :current-page="params.page"
            :per-page="$store.state.pagination.perPage"
            :total="total"
          >
            <template #cell(lotteryName)="data">
              {{
                $i18n.locale == "en"
                  ? data.item.lotteryName
                  : data.item.lotteryNameKh
              }}
            </template>
            <template #cell(lotteryTimeName)="data">
              {{
                moment(data.item.lotteryTimeName, "HH:mm")
                  .locale($i18n.locale)
                  .format("h:mm a")
              }}
            </template>
            <template #cell(turnover)="data">
              <span>
                {{ data.item.turnover | currency(data.item.ccy) }}
              </span>
            </template>
            <template #cell(commission)="data">
              <span class="text-danger">
                {{ data.item.commission | currency(data.item.ccy) }}
              </span>
            </template>
            <template #cell(netWl)="data">
              <span
                :class="{
                  'text-positive': data.item.netWl >= 0,
                  'text-danger': data.item.netWl < 0,
                }"
              >
                {{ data.item.netWl | currency(data.item.ccy) }}
              </span>
            </template>
            <template #cell(newWl)="data">
              <span
                :class="{
                  'text-positive': data.item.newWl >= 0,
                  'text-danger': data.item.newWl < 0,
                }"
              >
                {{ data.item.newWl | currency(data.item.ccy) }}
              </span>
            </template>
            <template #cell(betDate)="data">
              {{ data.item.betDate | formatDate("DD-MM-YYYY") }}
            </template>
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-button
                  v-b-tooltip.hover
                  :title="$t('general.view')"
                  variant="primary"
                  pill
                  size="sm"
                  @click="viewDetail(data.item)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </div>
            </template>
            <template #custom-foot>
              <b-tr v-if="!loading">
                <b-th colspan="4"></b-th>
                <b-th class="text-right">
                  <h5 class="font-weight-bold">
                    {{ totalReport.turnover | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th class="text-right">
                  <h5
                    class="font-weight-bold"
                    :class="{
                      'text-positive': totalReport.netWl >= 0,
                      'text-danger': totalReport.netWl < 0,
                    }"
                  >
                    {{ totalReport.netWl | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th class="text-right">
                  <h5 class="font-weight-bold text-danger">
                    {{ totalReport.commission | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th class="text-right">
                  <h5
                    class="font-weight-bold"
                    :class="{
                      'text-positive': totalReport.newWl >= 0,
                      'text-danger': totalReport.newWl < 0,
                    }"
                  >
                    {{ totalReport.newWl | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th></b-th>
                <b-th></b-th>
              </b-tr>
            </template>
          </n-table>
          <n-pagination
            class="mt-2"
            :total="total"
            :per-page="$store.state.pagination.perPage"
            :page="params.page"
            @change="list"
            ref="pagination"
          ></n-pagination>
        </div>
      </div>
    </b-modal>
    <bet-history-detail ref="betHistoryDetailModal" />
  </div>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BModal,
  BRow,
  BSpinner,
  BTh,
  BTr,
  BButton,
} from "bootstrap-vue";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import searchInputs from "./searchInput";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import NPagination from "@/components/NPagination";
import tableFields from "./tableFields";
import { calculateDatesByPeriod } from "@/libs/helper";
import Repository from "@/repositories/RepositoryFactory";
import vSelect from "vue-select";
import moment from "moment";
import BetHistoryDetail from "./Detail";
import NSingleSelect from "@/components/NSingleSelect";

const BetHistoryRepository = Repository.get("betHistory");
const LotteryRepository = Repository.get("lottery");

export default {
  components: {
    BModal,
    BSpinner,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BTr,
    BTh,
    BRow,
    BCol,
    BButton,
    NFormConfirmation,
    NInput,
    NSearchContainer,
    NSearchInput,
    NTable,
    NPagination,
    NSingleSelect,
    vSelect,
    BetHistoryDetail,
  },
  watch: {
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.endDate = dates[1];
      }
    },
    "params.lotteryTimeId"(value) {
      if (value) {
        this.params.post = null;
        this.getLotteryChannel(value);
      }
    },
    perPage(value) {
      this.list(1);
    },
    lotteryId(value) {
      if (value) {
        this.params.lotteryTimeId = null;
        this.getLotteryTime(value);
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      lotteryClass: `lottery-vn`,
      lotteries: [],
      lotteryTimes: [],
      channels: [],
      loading: false,
      userId: null,
      lotteryId: null,
      params: {
        page: 1,
        userId: null,
        startDate: null,
        endDate: null,
        period: null,
        lotteryTimeId: null,
        post: null,
      },
      total: 0,
      items: [],
      totalReport: {},
      isShow: false,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      moment,
    };
  },
  created() {
    this.getLottery();
  },
  methods: {
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.lotteries = [...data];
        }
      });
    },
    getLotteryTime(lottery) {
      const index = this.lotteries.findIndex((element) => {
        return element.id == lottery;
      });

      if (index != -1) {
        let times = this.lotteries[index].times;
        times = times.map((element) => {
          return {
            ...element,
            name: moment(element.name, "HH:mm")
              .locale(this.$i18n.locale)
              .format("h:mm a"),
          };
        });
        this.lotteryTimes = [...times];
        if (this.params.lotteryTimeId) {
          this.getLotteryChannel(this.params.lotteryTimeId);
        }
      }
    },
    getLotteryChannel(time) {
      const index = this.lotteryTimes.findIndex((element) => {
        return element.id == time;
      });

      if (index != -1) {
        this.channels = [...this.lotteryTimes[index].posts];
      }
    },
    viewDetail(item) {
      this.$refs.betHistoryDetailModal.show(item, {
        ...this.params,
        lotteryTimeId: item.lotteryTimeId,
        parentId: this.userId,
        userId: item.userId,
        lotteryId: this.lotteryId,
      });
    },
    show() {
      this.$bvModal.show("modal-bet-history");
      this.isShow = true;
      this.list();
    },
    hide() {
      this.isShow = false;
      this.$bvModal.hide("modal-bet-history");
    },
    changeData(data) {
      this.userId = data.userId;
      this.lotteryId = data.lotteryId;
      const index = this.lotteries.findIndex((element) => {
        return element.id == this.lotteryId;
      });
      if (index != -1) {
        this.lotteryClass = this.lotteries[index].type;
      }
      this.params = {
        ...this.params,
        ...this.data,
        ...data,
      };
    },
    list(page = 1) {
      this.params.page = page;
      this.getData();
    },
    search(searchText) {
      this.params.page = 1;
      this.params.search = searchText;
      this.getData();
    },
    reset() {
      this.params.userId = null;
      this.$refs.search.reset();
      this.params.page = 1;
      this.params.startDate = moment().format("YYYY-MM-DD");
      this.params.endDate = moment().format("YYYY-MM-DD");
      this.params.lotteryId = null;
      this.params.lotteryTimeId = null;
      this.params.post = null;
      this.params.period = "today";
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.getData();
    },
    getData() {
      if (this.isShow) {
        this.loading = true;
        BetHistoryRepository.index({
          ...this.params,
          userId: this.userId,
          lotteryId: this.lotteryId,
        })
          .then((response) => {
            let data = response.data.data;
            this.items = [...data.list];
            this.total = data.total;
            this.getTotal();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getTotal() {
      BetHistoryRepository.total({
        ...this.params,
        userId: this.userId,
        lotteryId: this.lotteryId,
      })
        .then((response) => {
          const data = response.data.data;
          this.totalReport = {
            ...data,
          };
        })
        .catch(() => {});
    },
  },
  setup() {
    const searchFields = [...searchInputs];
    let fields = [...tableFields];

    return {
      searchFields,
      fields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
