export default [
  {
    key: "post",
    label: "field.channel",
  },
  {
    key: "number",
    label: "field.number",
  },
  {
    key: "amount",
    label: "field.amount",
  },
  {
    key: "payoutAmount",
    label: "field.winLoss",
  },
];