export default [
  {
    key: 'startDate',
    label: 'field.fromDate',
    type: 'date',
    cols: 3,
    searchField: "createdAt",
    operator: 'dt>=',
  },
  {
    key: 'endDate',
    label: 'field.toDate',
    type: 'date',
    cols: 3,
    searchField: "createdAt",
    operator: 'dt<=',
  },
  {
    key: 'period',
    label: 'field.period',
    type: 'radio',
    options: [
      { text: 'field.today', value: 'today' },
      { text: 'field.yesterday', value: 'yesterday' },
      { text: 'field.thisWeek', value: 'thisWeek' },
      { text: 'field.lastWeek', value: 'lastWeek' },
      { text: 'field.thisMonth', value: 'thisMonth' },
      { text: 'field.lastMonth', value: 'lastMonth' },
    ],
    cols: 6,
    skip: true,
  },
  {
    key: 'lotteryId',
    label: 'field.lotteryName',
    type: '',
    cols: 3,
    skip: true,
  },
  {
    key: 'lotteryTimeId',
    label: 'field.lotteryTime',
    type: '',
    cols: 3,
  },
  {
    key: 'post',
    label: 'field.channel',
    type: '',
    cols: 3,
  },
  {
    key: 'digit',
    label: 'field.number',
    type: 'singleSelect',
    cols: 3,
    reduce: true,
    clearable: true,
    options: [
      { name: 2, id: 2 },
      { name: 3, id: 3 },
    ],
  },
]
