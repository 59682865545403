<template>
  <div>
    <b-modal
      id="invoice-detail-modal"
      size="xl"
      :title="$t('general.betHistory')"
      hide-footer
      centered
    >
      <div :class="params.lotteryId == 2 ? `lottery-kh` : `lottery-vn`">
        <n-table
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #thead-top>
            <b-tr class="text-center">
              <b-th :colspan="10">
                {{ invoice.userName }}
              </b-th>
            </b-tr>
          </template>
          <template #cell(lotteryName)="data">
            {{
              $i18n.locale == "en"
                ? data.item.lotteryName
                : data.item.lotteryNameKh
            }}
          </template>
          <template #cell(lotteryTimeName)="data">
            {{
              moment(data.item.lotteryTimeName, "HH:mm")
                .locale($i18n.locale)
                .format("h:mm a")
            }}
          </template>
          <template #cell(turnover)="data">
            <span>
              {{ data.item.turnover | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(commission)="data">
            <span class="text-danger">
              {{ data.item.commission | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(netWl)="data">
            <span
              :class="{
                'text-positive': data.item.netWl >= 0,
                'text-danger': data.item.netWl < 0,
              }"
            >
              {{ data.item.netWl | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(newWl)="data">
            <span
              :class="{
                'text-positive': data.item.newWl >= 0,
                'text-danger': data.item.newWl < 0,
              }"
            >
              {{ data.item.newWl | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(betDate)="data">
            {{ data.item.betDate | formatDate("DD-MM-YYYY") }}
          </template>
          <template #custom-foot>
            <b-tr v-if="!loading">
              <b-th colspan="5"></b-th>
              <b-th class="text-right">
                <h5 class="font-weight-bold">
                  {{ totalReport.turnover | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5
                  class="font-weight-bold"
                  :class="{
                    'text-positive': totalReport.netWl >= 0,
                    'text-danger': totalReport.netWl < 0,
                  }"
                >
                  {{ totalReport.netWl | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5 class="font-weight-bold text-danger">
                  {{ totalReport.commission | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5
                  class="font-weight-bold"
                  :class="{
                    'text-positive': totalReport.newWl >= 0,
                    'text-danger': totalReport.newWl < 0,
                  }"
                >
                  {{ totalReport.newWl | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th></b-th>
            </b-tr>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
          ref="pagination"
        ></n-pagination>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BLink, BTh, BTr } from "bootstrap-vue";
import NTable from "@/components/NTable.vue";
import NPagination from "@/components/NPagination";
import fields from "./detailTableColumn";
import moment from "moment";
import Repository from "@/repositories/RepositoryFactory";
import vSelect from "vue-select";

const BetNumberHistoryRepository = Repository.get("betNumberHistory");

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BTh,
    BTr,

    vSelect,
    NTable,
    NPagination,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      loading: false,
      params: {
        page: 1,
        sortby: "id",
        sort: "desc",
      },
      items: [],
      total: 0,
      totalReport: {},
      perPageOptions: this.$store.state.pagination.perPageOptions,

      fields,
      moment,
    };
  },
  methods: {
    show(invoice, params) {
      this.params = { ...params, page: 1, sortby: "id", sort: "desc" };
      this.invoice = { ...invoice };
      this.getData();
      this.$bvModal.show("invoice-detail-modal");
    },
    hide() {
      this.params = {
        page: 1,
        sortby: "id",
        sort: "desc",
      };
      this.invoice = {};
      this.$bvModal.hide("invoice-detail-modal");
    },
    list(page = 1) {
      this.params.page = page;
      this.getData();
    },
    getData() {
      this.loading = true;
      BetNumberHistoryRepository.index(this.params)
        .then((response) => {
          let data = response.data.data;
          this.items = [...data.list];
          this.total = data.total;
          this.getTotal();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getTotal() {
      BetNumberHistoryRepository.total(this.params)
        .then((response) => {
          const data = response.data.data;
          this.totalReport = {
            ...data,
          };
        })
        .catch(() => {});
    },
  },
};
</script>