<template>
  <b-modal
    id="modal-transaction-history"
    hide-footer
    centered
    :title="modalTitle"
    size="xl"
    @hide="isShow = false"
  >
    <n-search-container
      :active="true"
      :show-header="false"
      @search="search"
      @reset="reset"
      v-if="searchFields.length"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
              >
                {{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
        <template #stateType="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
                >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>
    <div>
      <b-row>
        <b-col>
          <label>{{ $t("general.entries") }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>
      </b-row>
      <n-table
        @sort-changed="sortChanged"
        :fields="fields"
        :items="items"
        :busy="loading"
        :current-page="params.page"
        :per-page="$store.state.pagination.perPage"
        :total="total"
      >
        <template #cell(balance)="data">
          {{ data.item.balance | currency(data.item.ccy) }}
        </template>
        <template #cell(deposit)="data">
          {{
            data.item.trxType === "cr"
              ? data.item.amount
              : 0 | currency(data.item.ccy)
          }}
        </template>
        <template #cell(withdraw)="data">
          {{
            data.item.trxType === "dr"
              ? data.item.amount
              : 0 | currency(data.item.ccy)
          }}
        </template>
        <template #cell(openBalance)="data">
          {{ data.item.openBalance | currency(data.item.ccy) }}
        </template>
        <template #cell(createdAt)="data">
          {{ data.item.createdAt | formatDate }}
        </template>
      </n-table>
      <n-pagination
        class="mt-2"
        :total="total"
        :per-page="$store.state.pagination.perPage"
        :page="params.page"
        @change="list"
        ref="pagination"
      ></n-pagination>
    </div>
  </b-modal>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BModal,
  BRow,
  BSpinner,
  BTh,
  BTr,
} from "bootstrap-vue";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import searchInputs from "./searchInput";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import NPagination from "@/components/NPagination";
import tableFields from "./tableFields";
import { calculateDatesByPeriod } from "@/libs/helper";
import Repository from "@/repositories/RepositoryFactory";
import vSelect from "vue-select";
import depositTableFields from "./depositTableFields";
import withdrawalTableFields from "./withdrawalTableFields";
import moment from "moment";

const BalanceReportRepository = Repository.get("balanceReport");

export default {
  components: {
    BModal,
    BSpinner,
    NFormConfirmation,
    NInput,
    NSearchContainer,
    NSearchInput,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    NTable,
    NPagination,
    BTr,
    BTh,
    BRow,
    BCol,
    vSelect,
  },
  watch: {
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.endDate = dates[1];
      }
    },
    perPage(value) {
      this.list(1);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      loading: false,
      data: {
        userId: null,
      },
      modalTitle: this.$t("button.transactionHistory"),
      params: {
        page: 1,
        startDate: null,
        endDate: null,
        period: null,
        stateType: "",
      },
      userId: null,
      username: null,
      total: 0,
      items: [],
      isShow: false,
      perPageOptions: this.$store.state.pagination.perPageOptions,
    };
  },
  methods: {
    show() {
      this.$bvModal.show("modal-transaction-history");
      this.isShow = true;
      this.list();
    },
    hide() {
      this.$bvModal.hide("modal-transaction-history");
    },
    changeData(userId, username, data) {
      this.userId = userId;
      this.username = username;
      this.params = {
        ...this.data,
        ...data,
      };
      if (this.params.stateType === "deposit") {
        this.fields = [...depositTableFields];
        this.modalTitle =
          this.$t("breadcrumb.depositTransactionHistory") +
          " - " +
          this.username;
      } else {
        this.fields = [...withdrawalTableFields];
        this.modalTitle =
          this.$t("breadcrumb.withdrawalTransactionHistory") +
          " - " +
          this.username;
      }
    },
    list(page = 1) {
      this.params.page = page;
      this.getData();
    },
    search(searchText) {
      this.params.page = 1;
      this.params.search = searchText;
      this.getData();
    },
    reset() {
      this.params.userId = null;
      this.$refs.search.reset();
      this.params.page = 1;
      this.params.startDate = moment().format("YYYY-MM-DD");
      this.params.endDate = moment().format("YYYY-MM-DD");
      this.params.period = "today";
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.getData();
    },
    getData() {
      if (this.isShow) {
        this.loading = true;
        this.params.searchFields = [...this.searchFields];
        BalanceReportRepository.transaction({
          ...this.params,
          userId: this.userId,
        })
          .then((response) => {
            let data = response.data.data;
            this.items = [...data.list];
            this.total = data.total;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
  setup() {
    const searchFields = [...searchInputs];
    let fields = [...tableFields];

    return {
      searchFields,
      fields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
