var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"bet-invoice-detail-modal","size":"xl","title":_vm.$t('general.betHistory'),"hide-footer":"","centered":""}},[(_vm.invoice.invoiceNo)?_c('div',{class:_vm.lotteryId == 2 ? "lottery-kh" : "lottery-vn"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.lotteryName"))+": "+_vm._s(_vm.$i18n.locale == "en" ? _vm.invoice.lotteryName : _vm.invoice.lotteryNameKh)+" ")]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.lotteryTime"))+": "+_vm._s(_vm.moment(_vm.invoice.lotteryTimeName, "HH:mm") .locale(_vm.$i18n.locale) .format("h:mm a"))+" ")]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.invoiceNumber"))+": "+_vm._s(_vm.invoice.invoiceNo)+" ")]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.amount"))+": "+_vm._s(_vm._f("currency")(_vm.invoice.amount,_vm.invoice.ccy))+" ")]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.commission"))+": "),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.invoice.commission,_vm.invoice.ccy))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.total"))+": "),_c('span',{staticClass:"text-positive"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.invoice.total,_vm.invoice.ccy))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.winLoss"))+": "),_c('span',{class:{
              'text-positive': _vm.invoice.winLose >= 0,
              'text-danger': _vm.invoice.winLose < 0,
            }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.invoice.winLose,_vm.invoice.ccy))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.payback"))+": "),_c('span',{class:{
              'text-positive': _vm.invoice.payoutAmount >= 0,
              'text-danger': _vm.invoice.payoutAmount < 0,
            }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.invoice.payoutAmount,_vm.invoice.ccy))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.date"))+": "+_vm._s(_vm._f("formatDate")(_vm.invoice.betDate))+" ")])],1),_c('n-table',{attrs:{"fields":_vm.fields,"items":_vm.invoice.detail},scopedSlots:_vm._u([{key:"cell(number)",fn:function(data){return [_c('b-link',{staticClass:"link-underline",on:{"click":function($event){return _vm.viewNumberDetail(data.item)}}},[_vm._v(" "+_vm._s(data.item.number)+" ")])]}},{key:"cell(amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.amount,data.item.ccy))+" ")]}},{key:"cell(total)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.total,data.item.ccy))+" ")]}},{key:"cell(commission)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.commission,data.item.ccy))+" ")]}},{key:"cell(payoutAmount)",fn:function(data){return [_c('b-link',{staticClass:"link-underline",class:{
              'text-positive': data.item.payoutAmount >= 0,
              'text-danger': data.item.payoutAmount < 0,
            },on:{"click":function($event){return _vm.viewWinLossNumberDetail(data.item)}}},[_vm._v(" "+_vm._s(_vm._f("currency")(data.item.payoutAmount,data.item.ccy))+" ")])]}}],null,false,2852793734)})],1):_vm._e()]),_c('b-modal',{attrs:{"id":"winlist-detail-modal","size":"xl","title":_vm.$t('field.result'),"hide-footer":"","centered":""}},[_c('b-row',_vm._l((_vm.results),function(result,i){return _c('b-col',{key:("result-" + i),attrs:{"cols":"auto"}},[_c('h4',{staticClass:"mb-0 result-text"},[_vm._v(" "+_vm._s(result)+" ")])])}),1),_c('h6',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t("field.winList"))+" ")]),_c('n-table',{staticClass:"bet-table",attrs:{"sticky-header":"","fields":_vm.winListTableColumn,"items":_vm.winList,"show-empty":"","empty-text":_vm.$t('general.noRecord')},scopedSlots:_vm._u([{key:"cell(number)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.number)+" ")]}},{key:"cell(amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.amount,data.item.ccy))+" ")]}},{key:"cell(total)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.total,data.item.ccy))+" ")]}},{key:"cell(commission)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.commission,data.item.ccy))+" ")]}},{key:"cell(payoutAmount)",fn:function(data){return [_c('span',{class:{
            'text-positive': data.item.payoutAmount > 0,
            'text-danger': data.item.payoutAmount < 0,
          }},[_vm._v(" "+_vm._s(_vm._f("currency")(data.item.payoutAmount,data.item.ccy))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }